
import { Vue, Component } from "vue-property-decorator";
import dayjs from "@/plugins/day-js";
import Page from "@/components/core/dashboard/Page.vue";
import { DataOptions, DataTableHeader } from "vuetify";

import Modal from "@/components/Modal.vue";
import SafetyService from "../services/safety-service";
import ReportsService, {
  ReportsFilter,
  ReportsList
} from "@/services/reports-service";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";

@Component({
  components: {
    Modal,
    Page
  }
})
export default class ReportsManage extends Vue {
  options: DataOptions;
  loading = true;
  service: ReportsService;
  safetyService: SafetyService;
  reports: ReportsList;
  loadingExcel: boolean;
  statusReports = [
    {
      name: "Disponivel para Download",
      id: 1
    },
    {
      name: "Retirado",
      id: 2
    }
  ];
  headers: Array<DataTableHeader>;
  filters: ReportsFilter = {
    page: 1,
    limit: 10
  };

  constructor() {
    super();
    this.service = ReportsService.getInstance();
    this.safetyService = SafetyService.getInstance();
    this.loadingExcel = false;
    this.headers = [
      { text: "Data da solicitação", value: "createdAt", sortable: false },
      { text: "Nome arquivo", value: "fileName", sortable: false },
      { text: "Tipo arquivo ", value: "type", sortable: false },
      { text: "Origem", value: "origin", sortable: false },
      { text: "Status", value: "status", sortable: false },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        cellClass: "text-end",
        class: "text-end"
      }
    ];
    this.reports = {
      data: [],
      total: 0
    };
    this.options = {} as DataOptions;
  }

  async created(): Promise<void> {
    this.fetchReports();
  }

  async fetchReports(): Promise<void> {
    if (this.hasPermissions(["LISTAR_RELATÓRIOS"])) {
      const [error, reports] = await this.service.listReports({
        page: 1,
        limit: -1
      });

      if (error || !reports) {
        this.$notify({
          type: "error",
          text: "Não foi possível obter a lista de relatórios para filtrar"
        });
      }
      this.loading = false;
    }
  }
  async downloadReport(id: number): Promise<void> {
    this.loadingExcel = true;

    const [error, data] = await this.service.generateReportXls(id);
    if (!error) {
      window.open(
        process.env.VUE_APP_LOAN_URL! +
          "/reports/internal/download-xls?token=" +
          data!.token,
        "_blank"
      );
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
    this.loadingExcel = false;
  }

  formatDate(date: string) {
    return dayjs(date).format("DD/MM/YYYY");
  }
}
