import HttpClient, { Response } from "./http-client";

export default class ReportsService extends HttpClient {
  private static classInstance?: ReportsService;

  private constructor() {
    super(process.env.VUE_APP_MARGIN_BASE_URL!);
  }

  public static getInstance(): ReportsService {
    if (!this.classInstance) {
      this.classInstance = new ReportsService();
    }

    return this.classInstance;
  }

  public async listReports(
    filters: ReportsFilter
  ): Promise<Response<ReportsList>> {
    const params = {
      page: filters.page || 1,
      limit: filters.limit || 10
    } as ReportsFilter;

    return this.axiosCall<ReportsList>({
      method: "GET",
      url: `/reports/internal/margin-base-register`,
      params
    });
  }

  public async generateReportXls(
    id: number
  ): Promise<Response<GenerateReportXlsData>> {
    return this.axiosCall<GenerateReportXlsData>({
      method: "POST",
      url: `/reports/internal/generate-xls-token`,
      params: {
        id
      }
    });
  }
}

export interface ReportsList {
  data: ReportsData[];
  total: number;
}

export interface ReportsData {
  id: number;
  createdAt: string;
  fileName: string;
  type: string;
  status: string;
}

export interface ReportsFilter {
  page: number;
  limit: number;
  createdAt?: string | null;
  fileName?: string;
  statusId?: number | null;
}

export interface GenerateReportXlsData {
  token: string;
}
